import React from 'react'
import fs from 'fs'
import Select from 'react-select'
import SampleInfo from './SampleInfo'
import moment from 'moment'
import Papa from 'papaparse'
import Button from '@material-ui/core/Button'
import {Storage, Auth} from 'aws-amplify'
import _ from 'lodash'
import AWS from 'aws-sdk'
import {downloadBlob} from './SampleInfo'

const {useState, useEffect, useRef} = React
const baseUrl = 'https://f4hpqz9g77.execute-api.us-east-1.amazonaws.com/prod'

async function downloadS3File(filekey) {
  const result = await Storage.get(filekey, { download: true, customPrefix: {
      public: '',
      protected: '',
      private: ''
    } });
  let filename = filekey.split('/').pop()
  downloadBlob(result.Body, filename);
}

function RunPipeline(props) {
  const [selectedRunId, setSelectedRunId] = useState(null)
  const [selectedAnalysisId, setSelectedAnalysisId] = useState(null)
  const [inputFileData, setInputFileData] = useState(null)
  const [errMsg, setErrMsg] = useState(null)
  const [genomeFileData, setGenomeFileData] = useState(null)
  const [plasmidFileData, setPlasmidFileData] = useState(null)
  const [genomes, setGenomes] = useState(null)
  const [plasmids, setPlasmids] = useState(null)
  const [recentRunLogs, setRecentRunLogs] = useState(null)

  const genomeInputEl = useRef(null)
  const plasmidInputEl = useRef(null)
  const pipelineInputEl = useRef(null)

  const {runs, analyses, runFetchStatus} = props

  useEffect(() => {
    if (genomes == null) {
      setGenomes([])
      Storage.list('bioinformatic_resources/genomes/', {
        customPrefix: {
          public: '',
          protected: '',
          private: ''
        }
      }).then(results => {
        const allGenomes = results.map(r => r.key.split('/')[2]).filter(g => g.slice(-6) == '.fasta')
        setGenomes(allGenomes)
      })
    }
    if (plasmids == null) {
      setPlasmids([])
      Storage.list('bioinformatic_resources/plasmids/', {
        customPrefix: {
          public: '',
          protected: '',
          private: ''
        }
      }).then(results => {
        const allPlasmids = results.map(r => r.key.split('/')[2]).filter(g => g.slice(-6) == '.fasta')
        setPlasmids(allPlasmids)
      })
    }
    if (recentRunLogs == null) {
      setRecentRunLogs([])
      Storage.list('ngs_pipeline_outputs/tmp_logs/', {
        customPrefix: {
          public: '',
          protected: '',
          private: ''
        }
      }).then(results => {
        const recentRuns = results.map(r => r.key)
        setRecentRunLogs(recentRuns)
      })
    }
  })

  const fastaUpload = (type) => {
    let file
    if (type == 'genome') {
      file = genomeFileData
    } else if (type == 'plasmid') {
      file = plasmidFileData
    } else {
      return
    }
    if (!file) {
      return
    }
    if (file.name.slice(-5) != 'fasta') {
      return
    }
    try {
      Storage.put(`bioinformatic_resources/${type}s/${file.name}`, file, {
        customPrefix: {
          public: '',
          protected: '',
          private: ''
        }
      }).then(() => {
        if (type == 'genome') {
          setGenomeFileData(null)
          genomeInputEl.current.value = ''
          setGenomes(prev => [...prev, file.name])
        } else {
          setPlasmidFileData(null)
          plasmidInputEl.current.value = ''
          setPlasmids(prev => [...prev, file.name])
        }
      })
    } catch (err) {
      console.log("errrrorr", err)
    }
  }

  const renderRunSelect = () => {
    const opts = runs.map(r => ({
      value: r.id,
      label: r.name
    }))
    return (<Select 
      styles={selectStyles}
      isClearable={true}
      formatOptionLabel={(o) => (<span style={{width: '100%', textAlign: 'left'}}>{o.label}</span>)}
      value={opts.find(o => o.value === selectedRunId)}
      options={opts}
      onChange={(o) => {
        if (!o) {
          setSelectedRunId(null)
          setSelectedAnalysisId(null)
          return
        }
        const run_analyses = analyses.filter(a => a.run_id == o.value)
        setSelectedRunId(o.value)
        if (run_analyses.length == 1){
          setSelectedAnalysisId(run_analyses[0].id)
        }
      }}
    />)
  }

  const formatOptionLabel = (opt) => {
    return (<span style={{width: '100%'}}>
      <span style={{float: 'left', display: 'block'}}>{opt.name}</span>
      <span>&nbsp;</span>
      <span style={{float: 'right', display: 'block'}}>{moment(opt.date).format('YYYY/MM/DD')}</span>
    </span>)
  }
  const renderAnalysisSelect = () => {
    const opts = analyses.filter(r => !selectedRunId || r.run_id == selectedRunId).map(r => {
      const details = JSON.parse(r.basespace_details)
      return {
        value: r.id, 
        label: details['Name'],
        name: details['Name'],
        date: r.date
      }
    })

    return (<Select 
      styles={selectStyles}
      formatOptionLabel={formatOptionLabel}
      value={opts.find(o => o.value === selectedAnalysisId) || null}
      options={opts}
      onChange={(o) => setSelectedAnalysisId(o.value)}
    />)
  }

  const fileSelected = e => {
    var fileobj = e.target.files[0]
    var resp = Papa.parse(fileobj, {
      header: true,
      complete: function(res, file) {
        setInputFileData(res)
      }
    })
  }

  const submitInput = async () => {
    let newErrMsg = []
    if (!inputFileData) {
      setErrMsg('Select a file')
      return
    }
    if (!selectedAnalysisId) {
      newErrMsg.push('Select the analysis with your samples')
    }
    const data = inputFileData.data.filter(d => d.Sample && d.Genome)
    const requiredFields = ['Sample', 'Genome', 'read_type', 'tn_end_sequence', 'Spacer']
    data.forEach(d => {
      requiredFields.forEach(f => {
        if (!d[f]) {
          newErrMsg.push(`${f} is a required field`)
        }
      })
      if (!d.Genome || genomes.indexOf(d.Genome) == -1) {
        newErrMsg.push(`${d.Sample} references a genome file not in s3`)
      }
      if (d.Plasmid && plasmids.indexOf(d.Plasmid) == -1) {
        newErrMsg.push(`${d.Sample} references a plasmid file not in s3`)
      }
    })
    if (newErrMsg.length) {
      setErrMsg(newErrMsg)
      return
    }
    for (const d of data) {
      const queueObj = {
        ...d,
        analysisId: selectedAnalysisId
      }

      const queueParams = {
        MessageAttributes: {
          'Title': {
            DataType: 'String',
            StringValue: `${selectedAnalysisId}_-_${d.Sample}`
          }
        },
        MessageBody: JSON.stringify(queueObj),
        QueueUrl: 'https://sqs.us-east-1.amazonaws.com/720731305432/IlluminaPipelineInputs'
      }
      const sqs = new AWS.SQS({apiVersion: '2012-11-05'})
      await sqs.sendMessage(queueParams, (err, data) => {
        if (err) {
          console.log('Error sending to queue', err)
        } else {
          console.log("success on queue sending")
        }
      })
    }
    const ecs = new AWS.ECS()
    const ecsParams = {
      cluster: 'pipeline-cluster',
      taskDefinition: 'illumina_pipeline',
      launchType: 'FARGATE',
      networkConfiguration: {
        awsvpcConfiguration: {
          subnets: ['subnet-c32eb78e']
        }
      }
    }
    ecs.runTask(ecsParams).promise().then(resp => {
      pipelineInputEl.current.value = ''
      setErrMsg(['Success! Running them now, give it up to 10 min per sample'])
    }).catch((e) => {
      setErrMsg(['Uhoh, error starting the pipeline. Probably not your fault, ask Chris'])
    })
  }

  const plasmidUrl = 'https://s3.console.aws.amazon.com/s3/buckets/sternberg-sequencing-data?prefix=bioinformatic_resources/plasmids/&showversions=false'
  const genomeUrl = 'https://s3.console.aws.amazon.com/s3/buckets/sternberg-sequencing-data?region=us-east-1&prefix=bioinformatic_resources/genomes/&showversions=false' 
  const selectedRun = runs.find(r => r.id === selectedRunId)
  const selectedAnalysis = analyses.find(a => a.id === selectedAnalysisId)
  return (
    <div style={{margin: '50px'}}>
    <div style={{textAlign: 'center'}}>
      <div>
        <div style={{width: '350px', display: 'inline-block', margin: '20px'}}>
          <p><b>Optional: </b>Filter by run (Note: some analyses might not be associated with any run)</p>
          {renderRunSelect()}
        </div>
        <div style={{width: '600px', display: 'inline-block', margin: '40px'}}>
          <p>
            <span>Select the analysis with your samples</span>
            {runFetchStatus != 'loaded' ? (
              <span>&nbsp;&nbsp;&nbsp;&nbsp;loading...</span>) : null}
          </p>
          {renderAnalysisSelect()}
        </div>
      </div>
      <div>
        <div style={{display: 'inline-block', width:'50%'}}>
          <p>Upload your input csv</p>
            <a style={{color:'blue', cursor:'pointer'}} onClick={() => downloadS3File('bioinformatic_resources/other_assets/example_input.csv')}>Download example input csv</a>
            <span style={{margin: '30px'}} />
            <a target="_blank" href='https://github.com/sternberglab/Illumina-pipeline'>Documentation</a>
          <p>
            <input type="file" ref={pipelineInputEl} onChange={e => fileSelected(e)} />
          </p>
          <div>
          {errMsg && (
            <div>
              {errMsg.map(e => {
                return <p style={{color: e.indexOf('Success') > -1 ? 'green' : 'red'}}>{e}</p>
              })}
            </div>
          )}
          <Button variant="contained" onClick={async () => await submitInput()}>Submit</Button>
          </div>
        </div>
        <div style={{display: 'inline-block', width:'50%'}}>
          <h3>Logs from recent runs</h3>
          {recentRunLogs && recentRunLogs.length ? (
            <div style={{marginLeft: 20}}>{recentRunLogs.map(r => (
              <p><a style={{color:'blue', cursor:'pointer'}} onClick={() => downloadS3File(r)}>{r.split('/').pop()}</a></p>)
              )}
            </div>
          ) : (<p>None</p>)}
        </div>
      </div>
      
      <div style={{marginTop: '100px', textAlign: 'left'}}>
        <p>Upload a new genome here <input ref={genomeInputEl} type="file" onChange={(e) => setGenomeFileData(e.target.files[0])} /><Button variant="contained" onClick={() => fastaUpload('genome')}>Upload</Button></p>
        {genomes && genomes.length ? (<div style={{marginLeft: 20}}><p><b>Existing genome options</b></p>{genomes.map(g => <p>{g}</p>)}</div>) : null}
        <p>Upload a new plasmid here <input ref={plasmidInputEl} type="file" onChange={(e) => setPlasmidFileData(e.target.files[0])} /><Button variant="contained" onClick={() => fastaUpload('plasmid')}>Upload</Button></p>
        {plasmids && plasmids.length ? (<div style={{marginLeft: 20}}><p><b>Existing plasmid options</b></p>{plasmids.map(g => <p>{g}</p>)}</div>) : null}
        <p>Use sensibly named files for these. Filenames shouldn't be changed once they've been used to run a sample! Fasta only</p>
      </div>
    </div>
    </div>
  )
}

const selectStyles = {container: base => ({
  ...base, 
  flex: 1
})}

export default RunPipeline