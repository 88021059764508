/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:7109fcc2-ec4d-4216-9a85-d9a4fe63e959",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_OXQ367yor",
    "aws_user_pools_web_client_id": "58sumb8deaoc5ob91hqtmea4qr",
    "oauth": {},
    "aws_content_delivery_bucket": "labui-20210331172818-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dafolzpkxwy07.cloudfront.net"
};


export default awsmobile;
