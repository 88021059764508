import React from 'react'
import Select from 'react-select'
import {Storage} from 'aws-amplify'
import {Collapse, IconButton} from '@material-ui/core'
const baseUrl = 'https://f4hpqz9g77.execute-api.us-east-1.amazonaws.com/prod'

const {useState, useEffect, useRef} = React

export function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
}

async function getS3svg(key) {
  const resp = await Storage.get(key, {
    customPrefix: {
      public: '',
      protected: '',
      private: ''
    }
  })
  const svg = await fetch(resp).then(res => res.text())
  return svg
}

async function downloadS3File(filekey) {
  const result = await Storage.get(filekey, { download: true, customPrefix: {
      public: '',
      protected: '',
      private: ''
    } });
  let fileparts = filekey.split('/')
  let filename = fileparts.pop()
  let sample = fileparts.pop()
  downloadBlob(result.Body, `${sample}_${filename}`);
}

const s3Root = 'https://s3.console.aws.amazon.com/s3/buckets/sternberg-sequencing-data?region=us-east-1&prefix='
function NgsData(props) {
  const [basespaceExpanded, setBasespaceExpanded] = useState(false)
  const [pipelineExpanded, setPipelineExpanded] = useState(false)
  const [plotSvgs, setPlotSvgs] = useState(null)
  const [outputCsvLinks, setOutputCsvLinks] = useState(null)

  const {sample, run, analyses} = props
  const s3_link = sample ? `${s3Root}${sample.s3_link}&showversions=false` : null
  const basespace_info = sample.basespace_info ? JSON.parse(sample.basespace_info) : null
  const pipeline_info = sample.pipeline_info ? JSON.parse(sample.pipeline_info) : null

  let types = ['raw', 'normalized', 'zoomed', 'trans_dist_no_overlap', 'trans_dist_overlap']
  useEffect(() => {
    const s3outputKey = `ngs_pipeline_outputs/${sample['appsession_id']}/${sample['name']}/`
    let plots = ['genome_normalized', 'genome_zoomed', 'genome_raw', 'trans_dist_no_overlap', 'trans_dist_overlapSvg']
    plots = plots.map(n => {return {s3Key: `${s3outputKey}plots/${n}.svg`}})
    async function fetchPlotSvgs() {
      if (sample.pipeline_info) {
        const svgResults = await Promise.all([
          getS3svg(`${s3outputKey}plots/genome_normalized.svg`),
          getS3svg(`${s3outputKey}plots/genome_zoomed.svg`),
          getS3svg(`${s3outputKey}plots/genome_raw.svg`),
          getS3svg(`${s3outputKey}plots/trans_dist_no_overlap.svg`),
          getS3svg(`${s3outputKey}plots/trans_dist_overlap.svg`)
        ])
        plots.forEach((pl, index) => pl['svg'] = svgResults[index])
        setPlotSvgs({
          normalized: plots[0], 
          zoomed: plots[1], 
          raw: plots[2], 
          trans_dist_overlap: plots[3], 
          trans_dist_no_overlap: plots[4]
        })
      } else {
        setPlotSvgs(null)
      }
    }
    async function fetchOutputLinks() {
      if (sample.pipeline_info) {
        const outputs = await Storage.list(`${s3outputKey}`, {
          customPrefix: {
            public: '',
            protected: '',
            private: ''
          }
        })
        const csvs = outputs.filter(item => item.key.slice(-4) == '.csv').map(i => i.key)
        setOutputCsvLinks(csvs)
      } else {
        setOutputCsvLinks(null)
      }
    }
    fetchPlotSvgs()
    fetchOutputLinks()
  }, [props.sample])
  return (
    <div>
      <h3>Sample Info</h3>
      <p>{sample ? sample.id : 'No sample selected'}</p>
      {s3_link ? (<a target="_blank" href={s3_link}>See files in s3</a>) : null }
      {basespace_info && (
        <div>
          <div>
            <IconButton onClick={() => setBasespaceExpanded(!basespaceExpanded)}>
              View basespace sample information
            </IconButton>
          </div>
          <Collapse in={basespaceExpanded}>
            <pre>{JSON.stringify(basespace_info, null, 2)}</pre>
          </Collapse>
        </div>
      )}

      {pipeline_info && (
        <div>
          <div>
            <IconButton onClick={() => setPipelineExpanded(!pipelineExpanded)}>
              View the pipeline run information
            </IconButton>
          </div>
          <Collapse in={pipelineExpanded}>
            <pre>{JSON.stringify(pipeline_info, null, 2)}</pre>
          </Collapse>
          <div>
            <p>Download output csvs:</p>
            {outputCsvLinks && (
              <div>{outputCsvLinks.map(key => {
                return <p><a style={{color:'blue', cursor:'pointer'}} onClick={() => downloadS3File(key)}>{`${sample.name}_${key.split('/').pop()}`}</a></p>
              })}</div>
            )}
          </div>
        </div>
      )}
      {plotSvgs && (
        <div>
          {types.map(type => {
            return (
              <div>
                <div dangerouslySetInnerHTML={{ __html: plotSvgs[type].svg}} />
                <a style={{color:'blue', cursor:'pointer'}} onClick={() => downloadS3File(plotSvgs[type].s3Key)}>Download</a>
                <hr />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default NgsData