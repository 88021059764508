import React from 'react';
import logo from './logo.svg';
import './App.css';
import {AppBar, Tabs, Tab, Box, Typography} from '@material-ui/core';
import NgsData from './components/NgsData';
import RunPipeline from './components/RunPipeline';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import {Storage, Auth} from 'aws-amplify'
import AWS from 'aws-sdk'

const {useState, useEffect} = React

const baseUrl = 'https://f4hpqz9g77.execute-api.us-east-1.amazonaws.com/prod'

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
function updateAWSAuth() {
  Auth.currentSession().then(res => {
    const CognitoUrl = `cognito-idp.us-east-1.amazonaws.com/${Auth._config.aws_user_pools_id}`
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: Auth._config.identityPoolId,
        Logins: {
          [CognitoUrl]: res.getIdToken().getJwtToken()
        }
      })
  })
  setTimeout(updateAWSAuth, 300000)
}


function App() {
  const [tab, setTab] = useState(0)
  const [authUpdating, setAuthUpdating] = useState(false)
  const [analyses, setAnalyses] = useState([])
  const [runs, setRuns] = useState([])
  const [runFetchStatus, setRunFetchStatus] = useState(null);

  useEffect(() => {
    if (!authUpdating) {
      updateAWSAuth()
      setAuthUpdating(true)
    }
    const url = `${baseUrl}/ngs-analyses`
    if (!runFetchStatus) {
      setRunFetchStatus('loading')

      fetch(url)
        .then(resp => resp.json())
        .then(resp => {
          const appsessions = resp['appsessions'].sort((a,b) => a.date > b.date ? -1 : 1)
          setRuns(resp['runs'])
          setAnalyses(appsessions)
          setRunFetchStatus('loaded')
        })  
    }
  })

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div className="App">
      <AppBar position="static">
        <Tabs value={tab} onChange={(e, val) => setTab(val)}>
          <Tab label="View NGS Data" />
        </Tabs>
      </AppBar>
      <TabPanel value={tab} index={0}>
        <NgsData 
          runs={runs}
          analyses={analyses}
          runFetchStatus={runFetchStatus}
        />
      </TabPanel>
    </div>
  );
}

export default withAuthenticator(App);
