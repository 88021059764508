import React from 'react'
import Select from 'react-select'
import SampleInfo from './SampleInfo'
import moment from 'moment'
import AWS from 'aws-sdk'
import _ from 'lodash'

const baseUrl = 'https://f4hpqz9g77.execute-api.us-east-1.amazonaws.com/prod'
    
class NgsData extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      samples: [],
      syncMessage: null,
      selectedRunId: null,
      selectedAnalysisId: null,
      selectedSampleId: null,
      sampleFetchStatus: null
    }
  }

	onClickSync = () => {
		const url = `${baseUrl}/syncBasespaceRuns`
    this.setState({syncMessage: 'Checking basespace... If this takes more than ~5 seconds, found a new run, check back in 10 min'})
    fetch(url)
      .then(resp => resp.json())
      .then(resp => {
        let msg = 'All up to date. No new or incomplete runs. '
        console.log("sync response: ", resp)
        if (resp.incomplete.length || resp.new_appsessions.length) {
          msg = `${resp.new_appsessions.length} new analyses found and added to downloading queue, and ${resp.incomplete.length} appsessions still downloading`
        }
        this.setState({syncMessage: msg})
      })
	}

  selectAnalysis = async (option) => {
    const analysisId = option.value
    this.setState({selectedAnalysisId: analysisId, sampleFetchStatus: 'loading', selectedSampleId: null})
    const client = new AWS.DynamoDB()
    const params = {
      TableName: 'Illumina_dataset',
      IndexName: 'appsession_id',
      ProjectionExpression: "id, appsession_id, #name, download_status",
      KeyConditionExpression: "appsession_id=:analysisId",
      ExpressionAttributeValues: {':analysisId': {'S': analysisId}},
      ExpressionAttributeNames: {'#name': 'name'},
      Limit: 1000
    }
    let samples = []
    let res = await client.query(params).promise()
    samples = samples.concat(res['Items'])
    while (res.LastEvaluatedKey) {
      res = await client.query({...params, ExclusiveStartKey: res.LastEvaluatedKey}).promise()
      samples = samples.concat(res['Items'])
    }
    samples = samples.map(s => _.mapValues(s, v => v['S'])).sort((a,b) => a.name > b.name ? 1 : -1)
    this.setState({samples, sampleFetchStatus: 'loaded'})
  }

  selectSample = async (opt) => {
    const sampleId = opt.value
    this.setState({selectedSampleId: sampleId})
    const client = new AWS.DynamoDB()
    let sample = await client.getItem({
      TableName: 'Illumina_dataset',
      Key: {
        'id': {'S': sampleId}
      }
    }).promise()
    sample = _.mapValues(sample['Item'], v => v['S'])

    const samples = [...this.state.samples]
    const sampleIndex = samples.findIndex(s => s.id === sampleId)
    samples[sampleIndex] = sample
    this.setState({samples})
  }

  formatOptionLabel = (opt) => {
    return (<span style={{width: '100%'}}>
      <span style={{float: 'left', display: 'block'}}>{opt.name}</span>
      <span>&nbsp;</span>
      <span style={{float: 'right', display: 'block'}}>{moment(opt.date).format('YYYY/MM/DD')}</span>
    </span>)
  }

  renderRunSelect = () => {
    const {selectedRunId} = this.state
    const {runs, analyses} = this.props
    const opts = runs.map(r => ({
      value: r.id,
      label: r.name
    }))
    return (<Select 
      styles={selectStyles}
      isClearable={true}
      formatOptionLabel={(o) => (<span style={{width: '100%', textAlign: 'left'}}>{o.label}</span>)}
      value={opts.find(o => o.value === selectedRunId)}
      options={opts}
      onChange={(o) => {
        if (!o) {
          this.setState({selectedRunId: null, selectedAnalysisId: null, selectedSampleId: null})
          return
        }
        const run_analyses = analyses.filter(a => a.run_id == o.value)
        this.setState({selectedRunId: o.value, selectedSampleId: null})
        if (run_analyses.length == 1){
          this.selectAnalysis({value: run_analyses[0].id})
        }
        }
      }
    />)
  }

  renderAnalysisSelect = () => {
    const {selectedAnalysisId, selectedRunId} = this.state
    const {analyses} = this.props
    const opts = analyses.filter(r => !selectedRunId || r.run_id == selectedRunId).map(r => {
      const details = JSON.parse(r.basespace_details)
      return {
        value: r.id, 
        label: details['Name'],
        name: details['Name'],
        date: r.date
      }
    })

    return (<Select 
      styles={selectStyles}
      formatOptionLabel={this.formatOptionLabel}
      value={opts.find(o => o.value === selectedAnalysisId) || null}
      options={opts}
      onChange={this.selectAnalysis}
    />)
  }

  renderSampleSelect = () => {
    const {samples, selectedSampleId} = this.state

    const opts =samples.map(s => {
      return {
        value: s.id,
        label: s.name,
      }
    })
    return (<Select
      styles={selectStyles}
      value={opts.find(s => s.value === selectedSampleId) || null}
      options={opts}
      onChange={this.selectSample}
    />)
  }

  render() {
    const { syncMessage, samples, selectedRunId, selectedAnalysisId, sampleFetchStatus, selectedSampleId} = this.state
    const {runs, analyses, runFetchStatus} = this.props
    const selectedRun = runs.find(r => r.id === selectedRunId)
    const selectedAnalysis = analyses.find(a => a.id === selectedAnalysisId)
    const selectedSample = samples.find(s => s.id === selectedSampleId)
    return (
      <div style={{margin: '50px'}}>
      <div style={{textAlign: 'center'}}>
        <p>Got some runs for you</p>
        {syncMessage && (<div><p>{syncMessage}</p></div>)}
        <button onClick={this.onClickSync}>Check basespace for new runs</button>
        <div>
          <div style={{width: '350px', display: 'inline-block', margin: '20px'}}>
            <p><b>Optional: </b>Filter by run (Note: some analyses might not be associated with any run)</p>
            {this.renderRunSelect()}
          </div>
          <div style={{width: '600px', display: 'inline-block', margin: '40px'}}>
            <p>
              <span>Choose an analysis</span>
              {runFetchStatus != 'loaded' ? (
                <span>&nbsp;&nbsp;&nbsp;&nbsp;loading...</span>) : null}
            </p>

            {this.renderAnalysisSelect()}
          </div>
          <div style={{width: '200px', display: 'inline-block', margin: '40px'}}>
            <div>
              <span>Sample datasets for the analysis</span>
              {sampleFetchStatus == 'loading' ? (
                <span>&nbsp;&nbsp;&nbsp;&nbsp;loading...</span>) : null}
            </div>
            {this.renderSampleSelect()}
          </div>
        </div>
        
      </div>
      {selectedSample && <SampleInfo
          run={selectedRun}
          analysis={selectedAnalysis}
          sample={selectedSample}
        />}
      </div>
    );
  }
}

const selectStyles = {container: base => ({
  ...base, 
  flex: 1
})}

export default NgsData